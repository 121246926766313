import React from 'react'

const LeafIcon = props => {
  return (
    <svg
      width='120px'
      height='118px'
      viewBox='0 0 120 118'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <desc>Created with sketchtool.</desc>
      <g
        id='Prototyping'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        opacity='0.35'
      >
        <g
          id='What-you-can-do'
          transform='translate(-355.000000, -4507.000000)'
          fill='#767676'
          fillRule='nonzero'
        >
          <g id='-Icons/leaf' transform='translate(355.000000, 4507.000000)'>
            <g id='leaf'>
              <path
                d='M30.7132867,92.7572285 C30.1090909,86.144603 27.0769231,81.3058227 24,76.3435469 C17.9132867,66.6547595 11.4685315,56.3934018 26.013986,30.111303 C26.5406993,29.1098663 26.1602797,27.869578 25.1636364,27.3382665 C24.1756643,26.7861852 22.9286713,27.1423576 22.3784615,28.1336901 C22.3781818,28.1342515 22.3779021,28.1348128 22.3776224,28.1353741 C6.64615385,56.5281243 13.7622378,67.8335807 20.4755245,78.5327863 C23.3286713,83.0235336 26.0699301,87.514281 26.6293706,93.1277151 C26.7314685,94.2593835 27.7281119,95.0938205 28.8559441,94.9913753 C29.9837762,94.8889301 30.8153846,93.8888968 30.7132867,92.7572285 Z'
                id='Path'
              ></path>
              <path
                d='M80.1454545,30.1898911 C79.4937063,31.9881547 78.5731469,33.6766757 77.4153846,35.1970744 C75.3753846,37.9549546 72.4111888,39.8806432 69.0685315,40.6196518 C65.5104895,41.3943057 61.2363636,40.9115504 56.358042,38.6998573 C49.2979021,35.5001998 41.0741259,28.7865325 32.0335664,17.234085 C37.1804196,16.7064221 46.3216783,15.0448456 53.1356643,9.84680558 C58.8979021,5.44587317 64.1006993,3.82920413 68.4083916,4.23337139 C70.2593007,4.38100471 72.0551049,4.93673469 73.6671329,5.8612673 C75.2579021,6.78636126 76.646993,8.02356215 77.751049,9.49877266 C79.5208392,11.9170401 80.6942657,14.7217925 81.1748252,17.6831597 C82.6344056,17.9587793 84.0738462,18.3337567 85.4825175,18.8058465 C85.086993,14.5239189 83.5401399,10.4300414 81.006993,6.9615004 C79.5633566,5.04142524 77.747972,3.43373769 75.6699301,2.23498882 C73.5331469,1.00957614 71.1527273,0.273654917 68.6993007,0.0794300937 C63.4405594,-0.358417773 57.2643357,1.49401551 50.606993,6.57978688 C42.2601399,12.9454212 29.6951049,13.3159079 28.151049,13.3159079 C27.0165035,13.2412492 26.0363636,14.1034727 25.961958,15.2418772 C25.9300699,15.7294039 26.0713287,16.2124399 26.3608392,16.6053803 C36.8335664,30.6501927 46.4223776,38.6661767 54.6573427,42.4271776 C60.4083916,45.0205842 65.5440559,45.5707007 69.9188811,44.6725513 C74.2282517,43.7227582 78.0483916,41.2379716 80.6713287,37.6782123 C82.0483916,35.8499167 83.146014,33.8254317 83.9272727,31.6718377 L83.6027972,31.5146615 C82.488951,30.9774559 81.3325874,30.5342752 80.1454545,30.1898911 Z'
                id='Path'
              ></path>
              <path
                d='M109.840559,47.9620237 C93.1692308,20.0857095 79.8545455,20.6021455 67.2671329,21.0961277 C61.9188811,21.3094382 56.7160839,21.5115218 51.6027972,19.1426526 C50.5353846,18.7679559 49.3672727,19.3326673 48.9941259,20.4037106 C48.6727273,21.3259978 49.0430769,22.3479235 49.8797203,22.8475191 C55.8881119,25.6205556 61.572028,25.4072451 67.4125874,25.1714809 C78.8251748,24.7673136 90.9090909,24.2957852 106.316084,50.0726749 C106.897063,51.0491318 108.156923,51.3685362 109.13007,50.7855811 C110.103217,50.2029066 110.421538,48.9387612 109.840559,47.9620237 Z'
                id='Path'
              ></path>
              <path
                d='M45.2027972,78.813458 C44.5762238,72.5264117 42.4727273,67.7886732 39.4629371,64.4542933 C36.4987413,61.1872746 32.4573427,59.1060939 28.0839161,58.593868 C25.8206993,58.3098283 23.5278322,58.366524 21.2811189,58.7622711 C21.2811189,58.8857666 21.2811189,58.9980353 21.2811189,59.1215309 C21.3779021,60.371362 21.5801399,61.6108082 21.8853147,62.8263974 C23.7574825,62.4870653 25.6702098,62.4340184 27.558042,62.6692213 C30.9504895,63.0489701 34.0928671,64.6434661 36.4083916,67.1599686 C38.8475524,69.8656439 40.5706294,73.8175015 41.1076923,79.1614909 C41.8797203,86.8855763 40.2013986,97.3826983 34.7748252,111.068251 C31.7426573,106.846948 25.7342657,99.7291137 17.8461538,96.4059607 C11.1328671,93.5880167 7.16083916,89.8943771 5.32587413,85.9762 C4.52055944,84.3129394 4.08895105,82.4925027 4.06153846,80.6434375 C4.07076923,78.793811 4.45146853,76.9646734 5.18041958,75.2657676 C6.38181818,72.5174302 8.21454545,70.09383 10.5286713,68.1928405 C10.0579021,66.7754484 9.68391608,65.3277437 9.40979021,63.8592693 C5.90937063,66.3426526 3.14797203,69.7345702 1.42097902,73.6715523 C0.485314685,75.8759479 0.00195804196,78.2473431 0,80.6434375 C0.0100699301,83.1144712 0.563916084,85.5526664 1.62237762,87.7837258 C3.86013986,92.5775986 8.57062937,97.0234385 16.2685315,100.268003 C26.5398601,104.579121 33.365035,116.37856 33.4321678,116.502055 C33.991049,117.488616 35.2408392,117.833562 36.2240559,117.27306 C36.6184615,117.047962 36.9275524,116.697964 37.1020979,116.277518 C43.9832168,100.166962 46.1090909,87.8286333 45.2027972,78.813458 Z'
                id='Path'
              ></path>
              <path
                d='M103.457343,74.9907093 C102.805315,74.0605632 101.525594,73.8368679 100.598601,74.4911136 C95.1944056,78.3194758 92.5426573,83.3827934 89.8125874,88.5808335 C84.4867133,98.685015 78.8587413,109.451582 48.9062937,109.911883 C47.7753846,109.930408 46.8738462,110.865325 46.8920291,112.000081 C46.9107692,113.134837 47.8425175,114.039442 48.9734266,114.021197 C81.365035,113.482027 87.5748252,101.671362 93.448951,90.4444936 C95.9328671,85.717982 98.3608392,81.0925122 102.959441,77.8591742 C103.886434,77.2052091 104.109371,75.9208553 103.457343,74.9907093 Z'
                id='Path'
              ></path>
              <path
                d='M118.919441,57.3586318 C118.538182,57.1402693 118.097343,57.049893 117.661538,57.1006945 L117.717483,57.1568289 C100.363636,59.2225727 88.648951,63.5561439 81.2643357,68.8776795 C76.151049,72.5713192 73.1076923,76.7364873 71.7426573,81.0363779 C70.4047552,85.2422435 70.6271329,89.7919319 72.3692308,93.8462347 C73.259021,95.9557633 74.4581818,97.9196232 75.9272727,99.6729794 L76.2181818,99.4708958 C77.2492308,98.7607963 78.2187413,97.9648114 79.1160839,97.0907997 C77.8914685,95.6402883 76.892028,94.0129537 76.151049,92.2632463 C74.7921678,89.1003568 74.6212587,85.5507017 75.6699301,82.2713334 C76.7888112,78.7910042 79.3286713,75.3331288 83.6811189,72.1671519 C89.958042,67.6764045 99.848951,63.8480424 114.372028,61.7261643 C112.257343,66.4751296 109.113287,75.1984064 110.198601,83.7532801 C111.116084,90.9721564 109.918881,96.293692 107.446154,99.8638362 C106.394685,101.396023 105.019301,102.676167 103.418182,103.61361 C101.820979,104.533933 100.055105,105.12194 98.2265734,105.342548 C95.2514685,105.669811 92.2416783,105.28501 89.4433566,104.219861 C88.460979,105.342829 87.4030769,106.397032 86.2769231,107.374611 C90.1681119,109.17091 94.4721678,109.873151 98.7300699,109.406674 C101.095105,109.11646 103.377902,108.353033 105.443357,107.161301 C107.570629,105.91568 109.396923,104.213406 110.791608,102.176571 C113.801399,97.8205461 115.267133,91.5334998 114.237762,83.2143904 C112.827972,72.0997907 119.664336,60.2105371 119.686713,60.1768565 C120.25035,59.1860853 119.906853,57.924466 118.919441,57.3586318 Z'
                id='Path'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LeafIcon
