import React, { useState } from 'react'

import BioIcon from '../../Shared/svg/BioIcon'
import EmissionsIcon from '../../Shared/svg/EmissionsIcon'
import EnergyIcon from '../../Shared/svg/EnergyIcon'
import GovernmentIcon from '../../Shared/svg/GovernmentIcon'
import LeafIcon from '../../Shared/svg/LeafIcon'
import SavePlantsIcon from '../../Shared/svg/SavePlantsIcon'
import {
  Wrapper,
  Node,
  HeaderWrapper,
  Title,
  ControlsWrapper,
  Button,
  Arrow,
  ArroLeftArm,
  ArrowRightArm,
  ContentWrapper,
  Content,
  IconContainer
} from './styled'

const Accordion = ({ data, isActions, isRecomendations }) => {
  const initialState = data.map((_, i) => ({ index: i, isOpen: false }))
  const [state, setState] = useState(initialState)

  const checkIsOpen = i => state[i].isOpen
  const toggleOpen = i =>
    setState(current => {
      const updated = [...current]
      updated[i].isOpen = !updated[i].isOpen
      return updated
    })

  const iconComponentSVG = name => {
    switch (name) {
      case 'bioSVG':
        return <BioIcon />
      case 'emissionsSVG':
        return <EmissionsIcon />
      case 'energySVG':
        return <EnergyIcon />
      case 'governmentSVG':
        return <GovernmentIcon />
      case 'leafSVG':
        return <LeafIcon />
      case 'savePlantsSVG':
        return <SavePlantsIcon />
      default:
        return null
    }
  }

  return (
    <Wrapper>
      {data.map(({ title, content, link, iconName, buttonText }, i) => (
        <Node
          isOpen={checkIsOpen(i)}
          key={i}
          isActions={isActions}
          isRecomendations={isRecomendations}
        >
          <HeaderWrapper
            isActions={isActions}
            isRecomendations={isRecomendations}
          >
            <Title
              isActions={isActions}
              isRecomendations={isRecomendations}
              onClick={e => toggleOpen(i)}
            >
              {title}
              {isRecomendations ? (
                <IconContainer isOpen={checkIsOpen(i)}>
                  {iconComponentSVG(iconName)}
                </IconContainer>
              ) : null}
            </Title>
            <ControlsWrapper isActions={isActions}>
              {isActions ? (
                <Button href={link} target='_blank'>
                  {buttonText}
                </Button>
              ) : null}
              <Arrow
                isOpen={checkIsOpen(i)}
                onClick={e => toggleOpen(i)}
                isActions={isActions}
                isRecomendations={isRecomendations}
              >
                <ArroLeftArm />
                <ArrowRightArm />
              </Arrow>
            </ControlsWrapper>
          </HeaderWrapper>
          <ContentWrapper
            isOpen={checkIsOpen(i)}
            isActions={isActions}
            isRecomendations={isRecomendations}
          >
            <Content dangerouslySetInnerHTML={{ __html: content }} />
          </ContentWrapper>
        </Node>
      ))}
    </Wrapper>
  )
}

export default Accordion
