import React from 'react'

import MainLayout from '../components/LayoutsRelated/Layouts/MainLayout'

import WhatYouCanDoPageWrapper from '../components/PageRelated/WhatYouCanDoPageWrapper/WhatYouCanDoPageWrapper'

const WhatYouCanDo = () => (
  <MainLayout isPage>
    <WhatYouCanDoPageWrapper />
  </MainLayout>
)
export default WhatYouCanDo
