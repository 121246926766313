import styled, { keyframes } from 'styled-components'

import {
  PageWrapperStyle,
  PageTitleStyle,
  PageNameComponent
} from '../../../styled/pageStyle'

import { siteBreakPoints, colors } from '../../../consts/styleConsts'
const { grey, darkGrey2 } = colors.grey
const { primary } = colors
const { dark3 } = colors.background
const { small } = siteBreakPoints
const container = 61.81
const width1300 = 1400
const width1100 = 1100

const FadeInLeft = keyframes`
  0% {opacity: 0; transform: translateX(-30px)}
  50% {transform: translateX(0)}
  100% {opacity: 1; transform: translateX(0)}
`
const FadeInBottom = keyframes`
  0% {opacity: 0; transform: translateY(30px)}
  50% {transform: translateY(0)}
  100% {opacity: 1; transform: translateY(0)}
`
const Scale = keyframes`
  0% {opacity: 0; transform: translate(25%, -20%) scale(0)}
  60% {opacity: 0; transform: translate(25%, -20%) scale(0.9)}
  100% {opacity: 0.13; transform: translate(25%, -20%) scale(1)}
`

export const PageWrapper = styled(PageWrapperStyle)``
export const PageName = styled(PageNameComponent)`
  opacity: 0;
  animation: ${FadeInLeft} 1.5s ease forwards 0.2s;
`
export const Container = styled.div`
  width: ${container}rem;
  margin: 0 auto;
  margin-bottom: 13.18rem;
  @media only screen and (max-width: ${width1300}px) {
    width: 100%;
    padding-left: 3rem;
  }
  @media only screen and (max-width: ${small}px) {
    padding-left: 1.25rem;
    padding-right: 1.87rem;
    margin-bottom: 7rem;
  }
`
export const PageTitleWrapper = styled.div`
  position: relative;
  margin-top: 3.45rem;
`
export const PageTitle = styled(PageTitleStyle)`
  max-width: 39.45rem;
  opacity: 0;
  animation: ${FadeInLeft} 1.5s ease forwards 0.4s;
`
export const EyeBackground = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: 34.09rem;
  transform: translate(25%, -20%);
  opacity: 0;
  animation: ${Scale} 1.5s ease forwards;
  @media only screen and (max-width: ${small}px) {
    width: 15rem;
  }
`
export const MessageWrapper = styled.div`
  margin: 0 auto;
  margin-top: 10.22rem;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${width1300}px) {
    padding-left: 0;
  }
  @media only screen and (max-width: ${width1100}px) {
    flex-direction: column;
    margin-left: 0;
    max-width: 35rem;
  }
  @media only screen and (max-width: ${small}px) {
    margin-top: 5rem;
    margin-bottom: 7rem;
    max-width: 20.93rem;
  }
`
export const MessageTitle = styled.span`
  font-family: Plex-Bold;
  width: 21.5rem;
  color: ${grey};
  font-size: 2.72rem;
  line-height: 3.5rem;
  font-weight: bold;
  letter-spacing: 0;
  text-align: right;
  text-transform: uppercase;
  position: relative;
  opacity: 0;
  animation: ${FadeInBottom} 1.5s ease forwards 0.6s;
  @media only screen and (max-width: ${width1100}px) {
    width: 100%;
    text-align: left;
  }
  @media only screen and (max-width: ${small}px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
export const MessageText = styled.span`
  color: ${darkGrey2};
  width: 26.18rem;
  font-family: Plex-Light;
  font-size: 1.54rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 2rem;
  padding-top: 0.45rem;
  margin-left: 2.4rem;
  position: relative;
  opacity: 0;
  animation: ${FadeInBottom} 1.5s ease forwards 0.8s;
  @media only screen and (max-width: ${width1100}px) {
    width: 100%;
    text-align: left;
    margin-left: 0;
  }
  @media only screen and (max-width: ${small}px) {
    font-size: 1.12rem;
    line-height: 1.43rem;
    padding-top: 1.25rem;
  }
`
export const TakeActionsWrapper = styled.div`
  width: 100%;
  padding-left: calc((100vw - ${container}rem) / 2);
  @media only screen and (max-width: ${width1300}px) {
    padding-left: 3rem;
  }
  @media only screen and (max-width: ${small}px) {
    padding-left: 1rem;
  }
`
export const TakeActionsTitleWrapper = styled.div`
  margin-bottom: 6.81rem;
  @media only screen and (max-width: ${small}px) {
    margin-bottom: 3.12rem;
  }
`
export const TakeActionsTitle = styled.span`
  margin-bottom: 0.45rem;
  display: block;
  color: ${primary};
  font-family: Plex-Bold;
  font-size: 2.72rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 3.5rem;
  text-transform: uppercase;
  @media only screen and (max-width: ${small}px) {
    font-size: 1.75rem;
    line-height: 2.31rem;
    margin-bottom: 0.31rem;
  }
`
export const TakeActionsTitleText = styled.span`
  color: ${grey};
  font-family: Plex-Light;
  font-size: 1.3rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 2.59rem;
  @media only screen and (max-width: ${small}px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
export const RecomendationsWrapper = styled.div`
  margin-top: 14.77rem;
  margin-right: 3.63rem;
  @media only screen and (max-width: ${width1300}px) {
    margin-right: 3rem;
  }
  @media only screen and (max-width: ${small}px) {
    margin-right: 0.93rem;
    margin-top: 5.45rem;
  }
`
export const RecomendationsContainer = styled.div`
  background: ${dark3};
  padding-left: calc((100vw - ${container}rem) / 2);
  padding-bottom: 5.22rem;
  padding-right: 9.09rem;
  @media only screen and (max-width: ${width1300}px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media only screen and (max-width: ${small}px) {
    padding-right: 0.93rem;
    padding-bottom: 3.12rem;
    padding-left: 1rem;
  }
`
export const RecomendationTitle = styled(TakeActionsTitle)`
  padding: 5rem 0;
  margin-bottom: 0;
  @media only screen and (max-width: ${small}px) {
    padding-top: 3.12rem;
    padding-bottom: 0.62rem;
  }
`
