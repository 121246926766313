import React from 'react'

const GovernmentIcon = props => {
  return (
    <svg
      width='120px'
      height='120px'
      viewBox='0 0 120 120'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <desc>Created with sketchtool.</desc>
      <g
        id='Prototyping'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        opacity='0.35'
      >
        <g
          id='What-you-can-do'
          transform='translate(-355.000000, -5166.000000)'
          fill='#767676'
          fillRule='nonzero'
        >
          <g
            id='Icons/government'
            transform='translate(355.000000, 5166.000000)'
          >
            <g id='government'>
              <path
                d='M56,44 L48,44 C46.8954305,44 46,44.8954305 46,46 L46,54 C46,55.1045695 46.8954305,56 48,56 L56,56 C57.1045695,56 58,55.1045695 58,54 L58,46 C58,44.8954305 57.1045695,44 56,44 Z M54,52 L50,52 L50,48 L54,48 L54,52 Z'
                id='Shape'
              ></path>
              <path
                d='M72,48 C73.1045695,48 74,47.1045695 74,46 C74,44.8954305 73.1045695,44 72,44 L64,44 C62.8954305,44 62,44.8954305 62,46 L62,54 C62,55.1045695 62.8954305,56 64,56 L72,56 C73.1045695,56 74,55.1045695 74,54 C74,52.8954305 73.1045695,52 72,52 L66,52 L66,48 L72,48 Z'
                id='Path'
              ></path>
              <path
                d='M40,76 L32,76 C30.8954305,76 30,76.8954305 30,78 L30,110 C30,111.104569 30.8954305,112 32,112 C33.1045695,112 34,111.104569 34,110 L34,80 L38,80 L38,110 C38,111.104569 38.8954305,112 40,112 C41.1045695,112 42,111.104569 42,110 L42,78 C42,76.8954305 41.1045695,76 40,76 Z'
                id='Path'
              ></path>
              <path
                d='M56,76 L48,76 C46.8954305,76 46,76.8954305 46,78 L46,110 C46,111.104569 46.8954305,112 48,112 C49.1045695,112 50,111.104569 50,110 L50,80 L54,80 L54,110 C54,111.104569 54.8954305,112 56,112 C57.1045695,112 58,111.104569 58,110 L58,78 C58,76.8954305 57.1045695,76 56,76 Z'
                id='Path'
              ></path>
              <path
                d='M72,76 L64,76 C62.8954305,76 62,76.8954305 62,78 L62,110 C62,111.104569 62.8954305,112 64,112 C65.1045695,112 66,111.104569 66,110 L66,80 L70,80 L70,110 C70,111.104569 70.8954305,112 72,112 C73.1045695,112 74,111.104569 74,110 L74,78 C74,76.8954305 73.1045695,76 72,76 Z'
                id='Path'
              ></path>
              <path
                d='M88,76 L80,76 C78.8954305,76 78,76.8954305 78,78 L78,110 C78,111.104569 78.8954305,112 80,112 C81.1045695,112 82,111.104569 82,110 L82,80 L86,80 L86,110 C86,111.104569 86.8954305,112 88,112 C89.1045695,112 90,111.104569 90,110 L90,78 C90,76.8954305 89.1045695,76 88,76 Z'
                id='Path'
              ></path>
              <path
                d='M106,96 C104.895431,96 104,96.8954305 104,98 L104,110 C104,111.104569 104.895431,112 106,112 C107.104569,112 108,111.104569 108,110 L108,98 C108,96.8954305 107.104569,96 106,96 Z'
                id='Path'
              ></path>
              <path
                d='M14,96 C12.8954305,96 12,96.8954305 12,98 L12,110 C12,111.104569 12.8954305,112 14,112 C15.1045695,112 16,111.104569 16,110 L16,98 C16,96.8954305 15.1045695,96 14,96 Z'
                id='Path'
              ></path>
              <path
                d='M118,80 L98,80 L98,70 C98,68.8954305 97.1045695,68 96,68 L88,68 L88,62 C88,60.8954305 87.1045695,60 86,60 L82,60 L82,40 L84,40 C85.1045695,40 86,39.1045695 86,38 C86,36.8954305 85.1045695,36 84,36 L46,36 L46,34 C46.0295141,26.9728838 51.2637357,21.0564509 58.234843,20.1705077 C65.2059504,19.2845645 71.7536914,23.7036529 73.54,30.5 C73.7186328,31.1930953 74.2534582,31.7382422 74.9430127,31.9300893 C75.6325672,32.1219364 76.372091,31.9313375 76.8830127,31.4300893 C77.3939344,30.9288411 77.5986328,30.1930953 77.42,29.5 C75.9211834,23.7065707 71.6411911,19.0368068 66,17.04 L66,10 C66,8.8954305 65.1045695,8 64,8 L62,8 L62,2 C62,0.8954305 61.1045695,-1.42108547e-14 60,-1.42108547e-14 C58.8954305,-1.42108547e-14 58,0.8954305 58,2 L58,8 L56,8 C54.8954305,8 54,8.8954305 54,10 L54,17.06 C46.8187277,19.5989508 42.0129368,26.3831256 42,34 L42,36 L36,36 C34.8954305,36 34,36.8954305 34,38 C34,39.1045695 34.8954305,40 36,40 L38,40 L38,60 L34,60 C32.8954305,60 32,60.8954305 32,62 L32,68 L24,68 C22.8954305,68 22,68.8954305 22,70 L22,80 L2,80 C0.8954305,80 -2.13162821e-14,80.8954305 -2.13162821e-14,82 L-2.13162821e-14,90 C-2.13162821e-14,91.1045695 0.8954305,92 2,92 L4,92 L4,118 C4,119.104569 4.8954305,120 6,120 C7.1045695,120 8,119.104569 8,118 L8,92 L22,92 L22,118 C22,119.104569 22.8954305,120 24,120 C25.1045695,120 26,119.104569 26,118 L26,72 L94,72 L94,118 C94,119.104569 94.8954305,120 96,120 C97.1045695,120 98,119.104569 98,118 L98,92 L112,92 L112,118 C112,119.104569 112.895431,120 114,120 C115.104569,120 116,119.104569 116,118 L116,92 L118,92 C119.104569,92 120,91.1045695 120,90 L120,82 C120,80.8954305 119.104569,80 118,80 L118,80 Z M58,12 L62,12 L62,16.12 C61.34,16.12 60.68,16 60,16 C59.3315195,15.9994982 58.6636247,16.0395719 58,16.12 L58,12 Z M4,88 L4,84 L22,84 L22,88 L4,88 Z M42,40 L78,40 L78,60 L42,60 L42,40 Z M36,68 L36,64 L84,64 L84,68 L36,68 Z M116,88 L98,88 L98,84 L116,84 L116,88 Z'
                id='Shape'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default GovernmentIcon
