import React from 'react'

const EnergyIcon = props => {
  return (
    <svg
      width='120px'
      height='114px'
      viewBox='0 0 120 114'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>B21E0C1B-F14E-430E-B913-A4C0EB502DE6</title>
      <desc>Created with sketchtool.</desc>
      <g
        id='Prototyping'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        opacity='0.345075335'
      >
        <g
          id='What-you-can-do'
          transform='translate(-355.000000, -4273.000000)'
          fill='#767676'
          fillRule='nonzero'
        >
          <g
            id='Icons/eco-light'
            transform='translate(355.000000, 4273.000000)'
          >
            <g id='eco-light'>
              <path
                d='M46.9502439,85.514583 L22.8936585,85.514583 C20.782439,85.514583 19.0653659,83.8035136 19.0653659,81.6996759 L19.0653659,70.1538462 C19.0653659,69.6521917 19.0243902,69.2263688 18.9404878,68.8938769 C18.5580488,66.6967082 17.3521951,64.8514754 15.6019512,63.6887259 C5.91414634,57.2372164 0.132682927,46.4730343 0.132682927,34.890261 C0.132682927,25.0399454 4.35902439,15.6193416 11.7287805,9.04727955 C19.0907317,2.48105066 28.9873171,-0.659150605 38.8897561,0.441378134 C54.3531707,2.14272557 66.844878,13.9918813 69.2682927,29.2495651 C71.4263415,42.8972881 65.6585366,56.1016886 54.2185366,63.7101143 C54.2146341,63.7120587 54.2107317,63.7159475 54.2068293,63.7198363 C52.4819512,64.8456422 51.2897561,66.6792086 50.9326829,68.7480471 C50.817561,69.2322019 50.7746341,69.6560805 50.7746341,70.1519018 L50.7746341,81.6977315 C50.7765854,83.8035136 49.0614634,85.514583 46.9502439,85.514583 Z M34.8721951,4.1065666 C27.2585366,4.1065666 20.0702439,6.8267781 14.3317073,11.9444312 C7.78926829,17.7795668 4.03707317,26.1424186 4.03707317,34.8883166 C4.03707317,45.1683439 9.17073171,54.7250554 17.7697561,60.4513048 C20.4058537,62.2032065 22.2243902,64.9875831 22.7570732,68.0908409 C22.8897561,68.6002729 22.9697561,69.3196998 22.9697561,70.1519018 L22.9697561,81.6977315 L46.9502439,81.6238444 L46.8760976,70.1519018 C46.8760976,69.3371994 46.9580488,68.5963841 47.1219512,67.9469555 C47.6292683,64.9642504 49.44,62.1876514 52.062439,60.4707488 C62.2165854,53.713969 67.3346341,41.9834215 65.4165854,29.856217 C63.3014634,16.5390414 51.964878,5.79235886 38.462439,4.3048951 C37.2585366,4.1726761 36.0585366,4.1065666 34.8721951,4.1065666 L34.8721951,4.1065666 Z'
                id='Shape'
              ></path>
              <path
                d='M34.9209756,100.756712 C28.017561,100.756712 22.4019512,95.1607368 22.4019512,88.28146 L22.4019512,83.5701859 C22.4019512,82.4968787 23.2741463,81.6257888 24.3531707,81.6257888 L45.4887805,81.6257888 C46.5678049,81.6257888 47.44,82.4968787 47.44,83.5701859 L47.44,88.28146 C47.44,95.1587924 41.8263415,100.756712 34.9209756,100.756712 Z M26.3043902,85.514583 L26.3043902,88.28146 C26.3043902,93.0160669 30.1697561,96.8679174 34.9209756,96.8679174 C39.6721951,96.8679174 43.537561,93.0160669 43.537561,88.28146 L43.537561,85.514583 L26.3043902,85.514583 Z'
                id='Shape'
              ></path>
              <path
                d='M46.9502439,85.514583 L22.8936585,85.514583 C20.782439,85.514583 19.0653659,83.8035136 19.0653659,81.6996759 L19.0653659,70.1538462 C19.0653659,69.6521917 19.0243902,69.2263688 18.9404878,68.8938769 C18.7941463,68.3125021 18.924878,67.6980727 19.2956098,67.2255842 C19.6663415,66.7530957 20.2321951,66.4769913 20.8331707,66.4769913 L49.0126829,66.4769913 C49.6136585,66.4769913 50.1814634,66.7530957 50.5502439,67.2255842 C50.9190244,67.6980727 51.0517073,68.3125021 50.9053659,68.8938769 C50.8214634,69.2341463 50.7785366,69.6580249 50.7785366,70.1538462 L50.7785366,81.6996759 C50.7765854,83.8035136 49.0614634,85.514583 46.9502439,85.514583 L46.9502439,85.514583 Z M22.9678049,70.363841 L22.9678049,81.6996759 L46.9482927,81.6257888 L46.8760976,70.363841 L22.9678049,70.363841 Z'
                id='Shape'
              ></path>
              <path
                d='M52.0253659,113.527512 L41.7365854,113.527512 C36.9034146,113.527512 32.9717073,109.609551 32.9717073,104.79328 L32.9717073,98.8123145 C32.9717073,97.7390073 33.8439024,96.8679174 34.9229268,96.8679174 C36.0019512,96.8679174 36.8741463,97.7390073 36.8741463,98.8123145 L36.8741463,104.79328 C36.8741463,107.464881 39.0556098,109.638717 41.7365854,109.638717 L52.0253659,109.638717 C61.324878,109.638717 68.8917073,102.088624 68.8917073,92.8080164 L68.8917073,78.7441924 C68.8917073,71.979635 74.4136585,66.4769913 81.2039024,66.4769913 L86.2556098,66.4769913 C87.3346341,66.4769913 88.2068293,67.3480812 88.2068293,68.4213884 C88.2068293,69.4946955 87.3346341,70.3657854 86.2556098,70.3657854 L81.2039024,70.3657854 C76.5678049,70.3657854 72.7941463,74.124305 72.7941463,78.7441924 L72.7941463,92.8080164 C72.7941463,104.231349 63.4770732,113.527512 52.0253659,113.527512 Z'
                id='Path'
              ></path>
              <path
                d='M101.754146,78.590585 L94.142439,78.590585 C88.7102439,78.590585 84.2907317,74.1865257 84.2907317,68.7733242 L84.2907317,66.4031042 C84.2907317,60.9899028 88.7102439,56.5877878 94.142439,56.5877878 L101.754146,56.5877878 C102.345366,56.5877878 102.905366,56.8561146 103.274146,57.3149923 C103.644878,57.77387 103.787317,58.3766331 103.658537,58.9521747 L103.646829,76.0920348 C103.773659,76.6675763 103.637073,77.3383933 103.268293,77.7953266 C102.899512,78.2561487 102.345366,78.590585 101.754146,78.590585 L101.754146,78.590585 Z M94.142439,60.476582 C90.862439,60.476582 88.1931707,63.1365171 88.1931707,66.4031042 L88.1931707,68.7733242 C88.1931707,72.0418557 90.862439,74.7017909 94.142439,74.7017909 L99.7463415,74.7017909 L99.7463415,60.476582 L94.142439,60.476582 Z'
                id='Shape'
              ></path>
              <path
                d='M104.67122,80.9977486 C102.290732,80.9977486 100.25561,79.3139007 99.8341463,76.9922906 C99.7814634,76.7550742 99.7463415,76.4284155 99.7463415,76.0920348 L99.7463415,59.0863381 C99.7463415,58.7499574 99.7814634,58.4213543 99.8497561,58.1141395 C100.25561,55.8664165 102.290732,54.1825687 104.67122,54.1825687 C107.385366,54.1825687 109.592195,56.3836261 109.592195,59.0882824 L109.592195,76.0939792 C109.592195,78.7966911 107.383415,80.9977486 104.67122,80.9977486 L104.67122,80.9977486 Z M104.67122,58.0694184 C104.179512,58.0694184 103.76,58.4096879 103.676098,58.8782876 L103.64878,59.0863381 L103.64878,76.0920348 C103.654634,76.1153676 103.670244,76.2767525 103.676098,76.3000853 C103.76,76.768685 104.179512,77.1089545 104.67122,77.1089545 C105.242927,77.1089545 105.689756,76.6636875 105.689756,76.0920348 L105.689756,59.0863381 C105.689756,58.5146853 105.242927,58.0694184 104.67122,58.0694184 L104.67122,58.0694184 Z'
                id='Shape'
              ></path>
              <path
                d='M117.916098,63.243459 L107.640976,63.243459 C106.561951,63.243459 105.689756,62.3723691 105.689756,61.2990619 C105.689756,60.2257547 106.561951,59.3546648 107.640976,59.3546648 L117.916098,59.3546648 C118.995122,59.3546648 119.867317,60.2257547 119.867317,61.2990619 C119.867317,62.3723691 118.993171,63.243459 117.916098,63.243459 Z'
                id='Path'
              ></path>
              <path
                d='M117.916098,75.823708 L107.640976,75.823708 C106.561951,75.823708 105.689756,74.9526181 105.689756,73.8793109 C105.689756,72.8060038 106.561951,71.9349139 107.640976,71.9349139 L117.916098,71.9349139 C118.995122,71.9349139 119.867317,72.8060038 119.867317,73.8793109 C119.867317,74.9526181 118.993171,75.823708 117.916098,75.823708 Z'
                id='Path'
              ></path>
              <g id='Group' transform='translate(18.536585, 19.930070)'>
                <path
                  d='M2.29268293,36.4146683 C2.13268293,36.4146683 1.97073171,36.3952243 1.81073171,36.3543919 C0.766829268,36.0899539 0.134634146,35.0341463 0.39804878,33.9938939 C0.614634146,33.142248 5.86146341,13.0741259 19.3365854,8.59812383 C20.364878,8.25590994 21.4653659,8.80811871 21.804878,9.82698277 C22.1463415,10.8458468 21.5941463,11.9463756 20.5717073,12.2866451 C9.12390244,16.0879413 4.2302439,34.7599864 4.18146341,34.9485929 C3.95512195,35.8294047 3.16292683,36.4146683 2.29268293,36.4146683 L2.29268293,36.4146683 Z'
                  id='Path'
                ></path>
                <path
                  d='M15.1707317,32.0436637 C10.284878,32.0436637 5.66243902,30.5814771 3.55902439,28.0032065 C3.51414634,27.9487634 3.47121951,27.8884871 3.43219512,27.8301552 C0.185365854,22.8058332 -3.2995122,11.3825004 6.75317073,4.11823299 C18.7668293,-4.56933311 36.0565854,3.59130138 36.7863415,3.94129285 C37.5297561,4.29906191 37.964878,5.08459833 37.8770732,5.9012451 C37.7873171,6.71983626 37.1921951,7.39259765 36.3882927,7.58314856 C36.3980488,7.58314856 31.9336585,9.06672352 31.5765854,19.587856 C31.395122,24.9660583 28.124878,28.9890159 22.3726829,30.9178578 C20.0917073,31.6839502 17.6,32.0436637 15.1707317,32.0436637 L15.1707317,32.0436637 Z M6.65170732,25.6290977 C8.63804878,27.8962647 15.617561,29.0804025 21.1278049,27.2332253 C24.0273171,26.2610268 27.5180488,24.12219 27.6760976,19.4575814 C27.92,12.2088692 29.9297561,8.2850759 31.857561,6.17540508 C26.6829268,4.40405936 16.3804878,1.96578543 9.04390244,7.26815623 C-0.206829268,13.9491046 5.91219512,24.4371823 6.65170732,25.6290977 L6.65170732,25.6290977 Z'
                  id='Shape'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default EnergyIcon
