import React from 'react'

import SocialShareSection from '../SocialShareSection/SocialShareSection'
import Accordion from '../Accordion/Accordion'
import {
  PageWrapper,
  PageTitleWrapper,
  PageTitle,
  EyeBackground,
  MessageWrapper,
  MessageTitle,
  MessageText,
  TakeActionsWrapper,
  TakeActionsTitleWrapper,
  TakeActionsTitle,
  TakeActionsTitleText,
  RecomendationsWrapper,
  RecomendationsContainer,
  RecomendationTitle,
  PageName,
  Container
} from './styled'

import EyeOpenedNoBg from '../../../images/eye-opened-main-page.svg'
import takeActionsData from './data/TakeActionsContent.json'
import recomendationsData from './data/RecomendationsContent.json'

const WhatYouCanDoPageWrapper = () => {
  return (
    <PageWrapper>
      <Container>
        <PageName>What you can do</PageName>
        <PageTitleWrapper>
          <EyeBackground src={EyeOpenedNoBg} />
          <PageTitle>Be the change you want to see in the world</PageTitle>
        </PageTitleWrapper>

        <MessageWrapper>
          <MessageTitle>
            Start acting today, before may be too late…
          </MessageTitle>
          <MessageText>
            Together we can take action to stop this escalating environmental
            catastrophe and protect the nature and all human race for the
            future.
          </MessageText>
        </MessageWrapper>
      </Container>

      <TakeActionsWrapper>
        <TakeActionsTitleWrapper>
          <TakeActionsTitle>Take Actions</TakeActionsTitle>
          <TakeActionsTitleText>Get Involved</TakeActionsTitleText>
        </TakeActionsTitleWrapper>

        <Accordion data={takeActionsData} isActions />
      </TakeActionsWrapper>

      <RecomendationsWrapper>
        <RecomendationsContainer>
          <RecomendationTitle>Recomendations</RecomendationTitle>
          <Accordion data={recomendationsData} isRecomendations />
        </RecomendationsContainer>
      </RecomendationsWrapper>

      <SocialShareSection />
    </PageWrapper>
  )
}

export default WhatYouCanDoPageWrapper
