import styled, { css } from 'styled-components'
import { buttonStyle } from '../../../styled/pageStyle'
import { removeBackgroundOnClick } from '../../../styled/extends'

import { siteBreakPoints, colors } from '../../../consts/styleConsts'
const { small } = siteBreakPoints
const { grey } = colors.grey
const { primary } = colors
const { lightBlue } = colors.secondary

const width950 = 950

export const Wrapper = styled.div``
export const Node = styled.div`
  position: relative;
  ${({ isActions }) =>
    isActions &&
    css`
      padding-top: 4.45rem;
      padding-bottom: ${({ isOpen }) => (isOpen ? 4.54 : 5)}rem;
      &:before {
        content: '';
        position: absolute;
        width: 200%;
        left: 0;
        top: 0;
        height: 2px;
        background: ${primary};
      }
      &:last-child:after {
        content: '';
        position: absolute;
        width: 200%;
        left: 0;
        bottom: 0;
        height: 2px;
        background: ${primary};
      }
      @media only screen and (max-width: ${small}px) {
        padding-top: 3.75rem;
        padding-bottom: 3.75rem;
      }
    `}
  ${({ isRecomendations }) =>
    isRecomendations &&
    css`
      padding-top: 3.63rem;
      padding-left: 8.18rem;
      padding-bottom: ${({ isOpen }) => (isOpen ? 3.63 : 4.09)}rem;
      &:before {
        content: '';
        position: absolute;
        width: 200%;
        right: 0;
        bottom: 0;
        height: 2px;
        background: ${primary};
      }
      @media only screen and (max-width: ${small}px) {
        padding-left: 0;
        padding-top: 3.12rem;
        padding-bottom: 3.75rem;
      }
    `}
`
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  aling-items: center;
  ${({ isActions }) =>
    isActions &&
    css`
      @media only screen and (max-width: ${width950}px) {
        flex-direction: column;
      }
    `}
  ${({ isRecomendations }) =>
    isRecomendations &&
    css`
      @media only screen and (max-width: ${small}px) {
        padding-left: 4.37rem;
      }
    `}
`

export const IconContainer = styled.div`
  width: 5.45rem;
  position: absolute;
  left: -2.72rem;
  transition: 0.2s ease;
  top: 50%;
  transform: translate(-100%, ${({ isOpen }) => (isOpen ? 0 : -50)}%);
  & > svg {
    width: 100%;
  }
  @media only screen and (max-width: ${small}px) {
    width: 3.12rem;
    left: -0.93rem;
    transform: translate(-100%, -50%);
  }
`
export const Icon = styled.img`
  width: 5.45rem;
  position: absolute;
  left: -2.72rem;
  transition: 0.2s ease;
  top: 50%;
  transform: translate(-100%, ${({ isOpen }) => (isOpen ? 0 : -50)}%);
  @media only screen and (max-width: ${small}px) {
    width: 3.12rem;
    left: -0.93rem;
    transform: translate(-100%, -50%);
  }
`
export const Title = styled.span`
  color: ${grey};
  font-family: Plex-Bold;
  font-weight: bold;
  letter-spacing: 0;
  position: relative;
  padding-right: 3.12rem;
  cursor: pointer;
  transition: .2s ease;
  &:hover {
    color: ${primary};
  }
    ${({ isActions }) =>
      isActions &&
      css`
        font-size: 1.54rem;
        line-height: 2rem;
        width: calc(100% - 22.27rem);
        @media only screen and (max-width: ${width950}px) {
          width: 100%;
        }
      `}
    ${({ isRecomendations }) =>
      isRecomendations &&
      css`
        font-size: 1.54rem;
        line-height: 2rem;
      `}

      @media only screen and (max-width: ${small}px) {
        font-size: 1.37rem;
        line-height: 1.62rem;
        padding-right: 0.93rem;
      }
`
export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ isActions }) =>
    isActions &&
    css`
      width: 23.27rem;
      @media only screen and (max-width: ${width950}px) {
        width: 100%;
        margin-top: 1.87rem;
        justify-content: space-between;
      }
    `}
`
export const Button = styled.a`
  ${buttonStyle};
`
export const Arrow = styled.div`
  ${removeBackgroundOnClick};
  position: relative;
  height: 100%;
  cursor: pointer;
  transition: 0.2s;
  & div {
    position: absolute;
    background: ${grey};
    width: 30px;
    height: 2px;
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
      top: 0;
      left: 0;
      background: ${lightBlue};
      transition: 0.2s;
    }
  }
  & div:last-child {  
    left: 50%;
    top: 35%;
    transform: rotate(46deg);
    transform-origin: left;
  }
  & div:first-child {
    top: 35%;
    right: 50%;
    transform: rotate(-46deg);
    transform-origin: right;
    &:before {
      transition-delay: 0.2s;
    }
  }
  &:hover {
    & div:first-child {
      &:before {
        transition-delay: 0s;
        width: 100%;
      }
    }
    & div:last-child {
      &:before {
        width: 100%;
        transition-delay: 0.2s;
      }
    }
  }
  ${({ isActions }) =>
    isActions &&
    css`
      width: 9.09rem;
      min-height: 2.59rem;
      @media only screen and (max-width: ${small}px) {
        width: 5rem;
      }
    `}
  ${({ isRecomendations }) =>
    isRecomendations &&
    css`
      width: 2.72rem;
    `}
  
  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: rotateX(180deg);
    `}

    @media only screen and (max-width: ${small}px) {
      & div {
        width: 20px!important;
      }
      ${({ isOpen, isRecomendations }) =>
        !isOpen &&
        css`
          transform: ${isRecomendations
            ? 'rotateX(180deg) translateY(-25%);'
            : 'rotateX(180deg);'};
        `}
    }
`
export const ArroLeftArm = styled.div`
  position: absolute;
  background: ${grey};
  width: 30px;
  height: 2px;
  top: 35%;
  right: 50%;
  transform: rotate(-46deg);
  transform-origin: right;
`
export const ArrowRightArm = styled.div`
  position: absolute;
  background: ${grey};
  width: 30px;
  height: 2px;
  left: 50%;
  top: 35%;
  transform: rotate(46deg);
  transform-origin: left;
`

export const ContentWrapper = styled.div`
  overflow: hidden;
  transition: 0.2s ease;
  max-height: ${({ isOpen }) => (isOpen ? 1000 : 0)}px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  ${({ isActions }) =>
    isActions &&
    css`
      padding-right: 9.09rem;
      margin-top: ${({ isOpen }) => (isOpen ? 4.09 : 0)}rem;
      @media only screen and (max-width: ${small}px) {
        margin-top: ${({ isOpen }) => (isOpen ? 3.75 : 0)}rem;
        padding-right: 0.93rem;
      }
    `}

  ${({ isRecomendations }) =>
    isRecomendations &&
    css`
      margin-top: ${({ isOpen }) => (isOpen ? 1.81 : 0)}rem;
      padding-right: 0;
    `}
`
export const Content = styled.div`
  color: ${grey};
  font-family: Plex-Light;
  font-size: 0.81rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.27rem;
  @media only screen and (max-width: ${small}px) {
    font-size: 0.87rem;
    line-height: 1.5rem;
  }
`
